import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/Container"
import ArticlesList from "../components/ArticlesList"
import AuthorSideBySide from "../components/AuthorSideBySide"
import { Box } from "@theme-ui/components"

const AuthorTemplate = ({ data, location, pageContext }) => {
  const { author } = pageContext
  return (
    <Layout location={location} title={`${author} Posts`}>
      <Seo
        title={`View Posts By ${author}`}
        description={`View posts about ${author}`}
      />
      <Container width={1200}>
        <AuthorSideBySide author={author} />

        <Box sx={{ pt: 4 }}>
          <ArticlesList articles={data.allBlog.nodes} />
        </Box>
      </Container>
    </Layout>
  )
}

export default AuthorTemplate

export const authorQuery = graphql`
  query PostsByAuthor($author: String) {
    allBlog(filter: { author: { eq: $author } }) {
      nodes {
        title
        subtitle
        media {
          cloudinaryId
          description
          name
        }
        readingTime
        tags
        id
        description
        ctaLink {
          url
          title
        }
        createdAt
        bodyHTML
        body
        author
      }
    }
  }
`
