import React from "react"
import { Box, Flex, Image, Heading, Text } from "theme-ui"

const AuthorSideBySide = ({ author }) => {
  const tempImage =
    "https://thumbs.dreamstime.com/b/person-gray-photo-placeholder-woman-costume-gray-background-person-gray-photo-placeholder-woman-136701308.jpg"

  return (
    <Flex sx={container}>
      <Box sx={boxLeftStyles}>
        <Image alt={author} src={tempImage} sx={imageStyle}></Image>
      </Box>
      <Box sx={boxRightStyles}>
        <Heading as="h1">Hey! I'm {author}</Heading>
        <Flex sx={line}>
          <Box as="span"></Box>
        </Flex>
        <Text as="p" sx={descriptionStyle}>
          AUTHOR_DESCRIPTION: Lorem ipsum dolor sit amet consectetur,
          adipisicing elit. Cum blanditiis nulla, voluptatem eius quisquam dicta
          eligendi eum nesciunt veniam earum suscipit voluptas distinctio ut.
        </Text>
      </Box>
    </Flex>
  )
}

export default AuthorSideBySide

const container = {
  flexWrap: "wrap",
  alignItems: "center",
  mt: [0, 0, 0, 4],
}

const boxLeftStyles = {
  width: ["100%", "100%", "100%", "50%"],
}
const boxRightStyles = {
  width: ["100%", "100%", "100%", "50%"],
  pt: [4, 4, 4, 0],
  textAlign: "center",
  px: [2, 2, 2, 5],
}

const imageStyle = {
  width: "100%",
  objectFit: "cover",
}

const line = {
  justifyContent: "center",
  span: {
    backgroundColor: "primary",
    width: 300,
    height: 3,
    my: 3,
  },
}

const descriptionStyle = {
  lineHeight: 1.75,
}
